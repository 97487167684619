import * as RadixLabel from "@radix-ui/react-label";
import classNames from "classnames";
import React from "react";
import Icon from "../Icon";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;

  /**
   * The label text to be rendered.
   */
  children: React.ReactNode;

  /**
   * Optional onClick event handler for the label element.
   */
  onClick?: React.MouseEventHandler<HTMLLabelElement>;

  /**
   * The id of the input or other element to be referenced by the label.
   */
  htmlFor?: string;

  /**
   * The icon to be rendered. Intended for use with @mui/icons-material.
   * You can pass in an icon like Icon={\<CheckIcon \/>}.
   * You can also pass in a straight-up SVG element like Icon={\<svg>...\</svg>}.
   * The icon will inherit the color of the label, but can be overridden by passing in the iconColor prop.
   */
  leadingIcon?: React.ReactNode | React.ReactSVGElement;
  trailingIcon?: React.ReactNode | React.ReactSVGElement;

  /**
   * The color of the icon. Defaults to the color of the label.
   */
  iconColor?: "primary" | "secondary" | "minimal" | "positive" | "warning" | "danger";
  iconSize?: "base" | "small" | "xs";

  size?: "base" | "sm" | "micro";
  type?: "default" | "bold";
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "tertiary"
    | "placeholder"
    | "action"
    | "code"
    | "purple"
    | "invert"
    | "positive"
    | "warning"
    | "danger";

  /**
   * Styled presets.
   */
  level?: "default" | "primary" | "secondary" | "danger" | "outline" | "subtle" | "code";

  disabled?: boolean;
}

export function Label(props: IProps) {
  const { type = "default", onClick, level = "default", disabled = false } = props;
  const labelSize = props.size ?? "base";
  const labelColor = props.color ?? "primary";
  const iconColor = props.iconColor ?? "secondary";

  function getIconSize() {
    if (props.iconSize) return props.iconSize;
    if (props.size === "sm") return "xs";
    return "xxs";
  }

  return (
    <RadixLabel.Root
      style={props.style}
      htmlFor={props.htmlFor}
      className={classNames(
        style.LabelWrapper,
        {
          [style[labelSize]]: true,
          [style[labelColor]]: true,
          [style[type]]: true,
          [style[`level-${level}`]]: true,
          [style.disabled]: disabled,
        },
        props.className
      )}
      onClick={onClick}
      data-testid="label"
    >
      {props.leadingIcon && <Icon Icon={props.leadingIcon} size={getIconSize()} color={iconColor} />}
      {props.children}
      {props.trailingIcon && <Icon Icon={props.trailingIcon} size={getIconSize()} color={iconColor} />}
    </RadixLabel.Root>
  );
}

export default Label;
