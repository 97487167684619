import TextItemTextInputs from "@/components/TextItemTextInputs/TextItemTextInputs";
import { updateTextItems } from "@/http/dittoProject";
import { projectIdAtom } from "@/stores/Project";
import { selectedTextItemIdsAtom, selectedTextItemsAtom } from "@/stores/ProjectSelection";
import { allTagsAtom, usersByIdAtom } from "@/stores/Workspace";
import { TextItemMetaData } from "@ds/organisms/TextItemMetadata";
import { ZTextItemsUpdate } from "@shared/types/DittoProject";
import {
  IFTextItem,
  ITextItemPopulatedComments,
  ITextItemStatus,
  ITipTapRichText,
  ZTextItemPluralType,
} from "@shared/types/TextItem";
import { IFUser } from "@shared/types/User";
import { useAtom, useAtomValue } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";
import { z } from "zod";
import style from "./style.module.css";

interface SkinnyPlural {
  rich_text: ITipTapRichText;
  form: z.infer<typeof ZTextItemPluralType>;
}

/**
 * If all statuses are the same, return that status. Otherwise, return "MIXED".
 *
 * @param textItems The text items to check.
 * @returns The merged status.
 */
function getMergedStatus(textItems: ITextItemPopulatedComments[]): ITextItemStatus | "MIXED" {
  const statuses = textItems.map((textItem) => textItem.status);

  let status: ITextItemStatus | "MIXED" = "MIXED";

  if (statuses.every((status) => status === statuses[0])) {
    status = statuses[0];
  }

  return status;
}

/**
 * If all assignees are the same, return that assignee. Otherwise, return "MIXED".
 *
 * @param textItems The text items to check.
 * @returns The merged assignee.
 */
function getMergedAssignee(textItems: ITextItemPopulatedComments[], usersById: Record<string, IFUser>) {
  const assignees = textItems.map((textItem) => textItem.assignee);

  if (assignees.every((assignee) => assignee === assignees[0])) {
    // If the common assignee does not exist in the workspace, return null.
    if (!assignees[0] || !usersById[assignees[0]]) return null;

    return { value: assignees[0], label: usersById[assignees[0]].name };
  }

  return { value: "MIXED", label: "Mixed Assignees" };
}

/**
 * Return only the tags common to all text items. Tags are case insensitive.
 *
 * @param textItems The text items to check.
 * @returns The merged tags.
 */
function getMergedTags(textItems: ITextItemPopulatedComments[]) {
  const tags = textItems.map((textItem) => textItem.tags);

  const intersect = (a: string[], b: string[]) =>
    a.filter((x) => b.map((y) => y.toLowerCase()).includes(x.toLowerCase()));

  const mergedTags = tags.reduce((acc, curr) => intersect(acc, curr));

  return mergedTags;
}

/**
 * If all texts are the same, return that text. Otherwise, return null.
 */
function getMergedText(textItems: ITextItemPopulatedComments[]) {
  const baseTexts = textItems.map((textItem) => textItem.rich_text);

  if (baseTexts.every((text) => text === baseTexts[0])) {
    return baseTexts[0];
  }

  return null;
}

/**
 * Only display the text field if all text items have the same text, and all
 * selected text items do not have plural forms.
 */
function shouldDisplayTextField(textItems: ITextItemPopulatedComments[]) {
  const text = textItems.map((textItem) => textItem.text);

  const hasPlurals = textItems.some((textItem) => textItem.plurals?.length > 0);

  return !hasPlurals && text.every((value) => value === text[0]);
}

/**
 * If there is only one text item, return its notes. Otherwise, return null.
 */
function getMergedNotes(textItems: ITextItemPopulatedComments[]) {
  return textItems.length === 1 ? textItems[0].notes ?? "" : null;
}

/**
 * The merged character limit is the minimum of all character limits.
 */
function getMergedCharacterLimit(textItems: ITextItemPopulatedComments[]) {
  const characterLimits = textItems.map((textItem) => textItem.characterLimit ?? Infinity);

  const minimum = Math.min(...characterLimits);

  return minimum === Infinity ? null : minimum;
}

/**
 * The merged plurals only exist if only one item is selected and it has plurals.
 */
function getMergedPlurals(textItems: ITextItemPopulatedComments[]) {
  return textItems.length === 1 ? textItems[0].plurals : null;
}

function MetadataPanel() {
  const projectId = useAtomValue(projectIdAtom);
  const usersById = useAtomValue(usersByIdAtom);
  const allTags = useAtomValue(allTagsAtom);
  const selectedTextItemIds = useAtomValue(selectedTextItemIdsAtom);
  const [selectedTextItems, setSelectedTextItems] = useAtom(selectedTextItemsAtom);

  const [baseText, setBaseText] = useState(getMergedText(selectedTextItems));
  const [status, setStatus] = useState(getMergedStatus(selectedTextItems));
  const [assignee, setAssignee] = useState(getMergedAssignee(selectedTextItems, usersById));
  const [tags, setTags] = useState(getMergedTags(selectedTextItems));
  const [notes, setNotes] = useState(getMergedNotes(selectedTextItems));
  const [characterLimit, setCharacterLimit] = useState(getMergedCharacterLimit(selectedTextItems));

  function resetState() {
    setBaseText(getMergedText(selectedTextItems));
    setStatus(getMergedStatus(selectedTextItems));
    setAssignee(getMergedAssignee(selectedTextItems, usersById));
    setTags(getMergedTags(selectedTextItems));
    setNotes(getMergedNotes(selectedTextItems));
    setCharacterLimit(getMergedCharacterLimit(selectedTextItems));
  }

  useEffect(resetState, [selectedTextItemIds, selectedTextItems, usersById]);

  const [plurals, setPlurals] = useState<SkinnyPlural[] | null>(getMergedPlurals(selectedTextItems));

  const calculateChanges = useCallback(
    (textItem: ITextItemPopulatedComments) => {
      return {
        baseTextChanged: baseText && JSON.stringify(baseText) !== JSON.stringify(textItem.rich_text),
        statusChanged: status !== "MIXED" && status !== textItem.status,
        assigneeChanged: assignee?.value !== "MIXED" && (assignee?.value || null) !== textItem.assignee,
        tagsChanged: JSON.stringify(tags) !== JSON.stringify(textItem.tags),
        notesChanged: notes !== null && notes !== (textItem.notes ?? ""),
        characterLimitChanged: characterLimit !== null && characterLimit !== (textItem.characterLimit ?? null),
        pluralsChanged:
          plurals !== null &&
          JSON.stringify(plurals) !==
            JSON.stringify(
              textItem.plurals?.map((p) => ({
                rich_text: p.rich_text,
                form: p.form,
              })) || []
            ),
      };
    },
    [baseText, status, assignee, tags, notes, characterLimit, plurals]
  );

  const hasChanges = useMemo(() => {
    const anyTextItemChanged = Object.values(selectedTextItems).some((textItem) =>
      Object.values(calculateChanges(textItem)).some((value) => value)
    );

    return anyTextItemChanged;
  }, [selectedTextItems, calculateChanges]);

  if (!projectId) {
    return <div className={style.wrapper}>No project selected</div>;
  }

  const onCancel = resetState;

  const onSave = async () => {
    // If any of the selected text items have been changed, that attribute will be set to true.
    const changes = Object.values(selectedTextItems)
      .map((textItem) => calculateChanges(textItem))
      .reduce((acc, change) => {
        Object.keys(change).forEach((key) => {
          acc[key] = acc[key] || change[key];
        });
        return acc;
      }, {} as ReturnType<typeof calculateChanges>);

    const [request] = updateTextItems({
      projectId,
      updates: selectedTextItems.map((textItem) => {
        /**
         * To compute the set of tags to write to the current text item, we take the current
         * tags of the text item, subtract the tags that are common to all selected text items,
         * and add the tags that are present in the shared input field.
         */
        const commonTags = getMergedTags(selectedTextItems).map((tag) => tag.toLowerCase());

        const currentTagsMinusCommonTags = textItem.tags.filter((tag) => !commonTags.includes(tag.toLowerCase()));

        const tagsToWrite = [...currentTagsMinusCommonTags, ...tags];

        const update: z.infer<typeof ZTextItemsUpdate> = {
          textItemIds: [textItem._id],
          ...(changes.baseTextChanged ? { richText: baseText! } : {}),
          ...(changes.statusChanged ? { status: status as ITextItemStatus } : {}),
          ...(changes.assigneeChanged ? { assignee: assignee?.value } : {}),
          ...(changes.tagsChanged ? { tags: tagsToWrite } : {}),
          ...(changes.notesChanged ? { notes: notes! } : {}),
          ...(changes.characterLimitChanged ? { characterLimit } : {}),
          ...(changes.pluralsChanged ? { plurals } : {}),
        };

        return update;
      }),
    });

    const result = await request;
    const updatedTextItems = selectedTextItems.map((textItem) => result.data[textItem._id.toString()]);

    // Update text item to changed values
    setSelectedTextItems(updatedTextItems);
  };

  return (
    <div className={style.wrapper}>
      <TextItemMetaData
        status={status}
        setStatus={setStatus}
        users={usersById}
        assignee={assignee ? { id: assignee.value, name: assignee.label } : null}
        setAssignee={(value) => setAssignee(value ? { value: value.id, label: value.name } : null)}
        allTags={allTags.map((tag) => ({ value: tag._id, label: tag._id }))}
        tags={tags.map((tag) => ({ value: tag, label: tag }))}
        setTags={(values) => setTags(values.map((tag) => tag.value))}
        notes={notes}
        setNotes={setNotes}
        characterLimit={characterLimit ?? 0}
        setCharacterLimit={setCharacterLimit}
        onCancel={onCancel}
        onSave={onSave}
        onInsertVariable={() => {}}
        showCTAButtons={hasChanges}
        displayTextField={shouldDisplayTextField(selectedTextItems)}
        richTextInput={
          <RichTextInput
            selectedTextItems={selectedTextItems}
            setBaseText={setBaseText}
            setPlurals={setPlurals}
            setCharacterLimit={setCharacterLimit}
          />
        }
      />
    </div>
  );
}

function RichTextInput(props: {
  selectedTextItems: ITextItemPopulatedComments[];
  setBaseText: (richText: ITipTapRichText) => void;
  setPlurals: (plurals: SkinnyPlural[]) => void;
  setCharacterLimit: (characterLimit: number | null) => void;
}) {
  const { selectedTextItems, setBaseText, setPlurals, setCharacterLimit } = props;

  return (
    <TextItemTextInputs
      overrideClassname={style.richTextInput}
      hideTopLabels={true}
      textItem={selectedTextItems[0]}
      legacyHandleTextChange={function (
        fieldStates: {
          label: string | undefined;
          form: string | undefined;
          value: { text: string; richText: ITipTapRichText; variables: IFTextItem["variables"] };
        }[]
      ): void {
        const base = fieldStates.find((fieldState) => fieldState.form === undefined);
        const nonBases = fieldStates.filter((fieldState) => fieldState.form !== undefined);

        if (nonBases.length === 0 && base) {
          setBaseText(base.value.richText);
        } else if (nonBases[0]) {
          setBaseText(nonBases[0].value.richText);
        }

        setPlurals(
          nonBases.map((fieldState) => ({
            rich_text: fieldState.value.richText,
            form: fieldState.form as z.infer<typeof ZTextItemPluralType>,
          }))
        );
      }}
      readonly={false}
      isBaseText={true}
      isVariant={false}
      shouldShowRichText={true}
      handleCharacterLimitChange={(val) => {
        setCharacterLimit(val);
      }}
    />
  );
}

export default MetadataPanel;
