import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import { ActualComponentStatus } from "../../../../shared/types/TextItem";
import { IFUser } from "../../../../shared/types/User";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import TextInput from "../../atoms/TextInput";
import LabelWrapper from "../../molecules/LabelWrapper";
import MultiCombobox from "../../molecules/MultiCombobox";
import Select, { SelectOption } from "../../molecules/Select";
import { AvatarIcon, FlagIcon, InfoIcon, StatusIcon, TagIcon } from "./icons";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  status: ActualComponentStatus | "MIXED";
  setStatus: (value: ActualComponentStatus) => void;
  users: Record<string, IFUser>;
  assignee: { name: string; id: string } | null;
  setAssignee: (value: { name: string; id: string } | null) => void;
  allTags: {
    value: string;
    label: string;
  }[];
  tags: {
    value: string;
    label: string;
  }[];
  setTags: (value: { value: string; label: string }[]) => void;
  notes: string | null;
  setNotes: (value: string) => void;
  characterLimit: number;
  setCharacterLimit: (value: number) => void;
  showCTAButtons?: boolean;
  onCancel: () => void;
  onSave: () => void;
  onInsertVariable: () => void;
  richTextInput: React.ReactNode;
  displayTextField?: boolean;
  inputMaxWidth?: number;
  inputSize?: "base" | "small";
}

export function TextItemMetaData(props: IProps) {
  const characterLimitInputRef = useRef<HTMLInputElement>(null);
  const inputSize = props.inputSize ?? "base";
  const labelHeight = props.inputSize === "small" ? 28 : 32;

  useEffect(
    function setCharacterLimitInputSize() {
      if (characterLimitInputRef.current) {
        characterLimitInputRef.current.style.width = `${(props.characterLimit + "").length * 7 + 18}px`;
      }
    },
    [props.characterLimit]
  );

  return (
    <div
      style={props.style}
      className={classNames(style.TextItemMetaDataWrapper, props.className)}
      data-testid="textItemMetaData"
    >
      {(props.displayTextField ?? true) && (
        <div className={style.textDetails}>
          <div className={style.firstRow}>
            <Text size="small" weight="strong">
              Text
            </Text>

            {/* dummy button just to show what the dropdown will look like  */}
            <button className={style.editingDropdown}>
              Editing <Icon Icon={<KeyboardArrowDownIcon />} size="small" />
            </button>
          </div>
          {props.richTextInput}
        </div>
      )}

      <div className={style.metadataInputs}>
        <LabelWrapper
          className={style.labelWrapper}
          labelFlexStart
          labelHeight={labelHeight}
          label="Status"
          leadingIcon={<FlagIcon />}
        >
          <div className={style.inputWidthWrapper}>
            <StatusSelect status={props.status} setStatus={props.setStatus} size={inputSize} />
          </div>
        </LabelWrapper>

        <LabelWrapper
          className={style.labelWrapper}
          labelFlexStart
          labelHeight={labelHeight}
          label="Assign"
          leadingIcon={<AvatarIcon />}
        >
          <div className={style.inputWidthWrapper}>
            <Select
              size={inputSize}
              value={props.assignee?.id ?? ""}
              placeholder="Add teammate..."
              onChange={(value) => props.setAssignee(value ? { id: value, name: props.users[value].name } : null)}
            >
              {[
                ...Object.entries(props.users).map(([id, user]) => (
                  <SelectOption key={id} label={user.name} value={id} />
                )),
                <SelectOption key={"MIXED"} disabled={true} label="Mixed Assignees" value="MIXED" />,
              ]}
            </Select>
          </div>
        </LabelWrapper>

        <LabelWrapper
          className={style.labelWrapper}
          labelFlexStart
          labelHeight={labelHeight}
          label="Tags"
          leadingIcon={<TagIcon />}
        >
          <div className={style.inputWidthWrapper}>
            <MultiCombobox
              size={inputSize}
              placeholder="Add tag..."
              options={props.allTags.map((tag) => ({ value: tag.value, label: tag.label.toUpperCase() }))}
              selectedItems={props.tags.map((tag) => ({ value: tag.value, label: tag.label.toUpperCase() }))}
              setSelectedItems={(value) => props.setTags(value)}
              onCreateNew={(value) => props.setTags([...props.tags, { value, label: value }])}
            />
          </div>
        </LabelWrapper>

        {props.notes !== null && (
          <LabelWrapper
            className={style.labelWrapper}
            labelFlexStart
            labelHeight={labelHeight}
            label="Notes"
            leadingIcon={<InfoIcon />}
          >
            <div className={style.inputWidthWrapper}>
              <TextInput
                placeholder="Add notes..."
                size={inputSize}
                expand="block"
                value={props.notes}
                onChange={(value) => props.setNotes(value)}
              />
            </div>
          </LabelWrapper>
        )}
      </div>

      {props.showCTAButtons && (
        <div className={style.ctaButtons}>
          <Button onClick={props.onCancel} className={style.cancelButton}>
            Cancel
          </Button>
          <Button onClick={props.onSave}>Save</Button>
        </div>
      )}
    </div>
  );
}

// Should move to its own organism?
interface IStatusSelectProps {
  size?: "base" | "small";
  status: ActualComponentStatus | "MIXED";
  setStatus: (status: ActualComponentStatus) => void;
}

function StatusSelect(props: IStatusSelectProps) {
  return (
    <Select
      size={props.size ?? "base"}
      value={props.status}
      iconSize="xxxs"
      onChange={(value) => props.setStatus(value as ActualComponentStatus)}
      leadingIcon={<StatusIcon status={props.status} />}
    >
      <SelectOption leadingIcon={<StatusIcon status="NONE" />} iconSize="xxxs" label="None" value="NONE" />
      <SelectOption leadingIcon={<StatusIcon status="WIP" />} iconSize="xxxs" label="Work in Progress" value="WIP" />
      <SelectOption
        leadingIcon={<StatusIcon status="REVIEW" />}
        iconSize="xxxs"
        label="Ready for Review"
        value="REVIEW"
      />
      <SelectOption leadingIcon={<StatusIcon status="FINAL" />} iconSize="xxxs" label="Final" value="FINAL" />
      <SelectOption
        disabled={true}
        leadingIcon={<StatusIcon status="MIXED" />}
        iconSize="xxxs"
        label="Mixed"
        value="MIXED"
      />
    </Select>
  );
}

export default TextItemMetaData;
